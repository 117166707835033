@font-face {
  font-family: "LexendBold";
  src: url("/src/assets/fonts/lexend/Lexend-Bold.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "LexendExtraBold";
  src: url("/src/assets/fonts/lexend/Lexend-ExtraBold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendBlack";
  src: url("/src/assets/fonts/lexend/Lexend-Black.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendExtraLight";
  src: url("/src/assets/fonts/lexend/Lexend-ExtraLight.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendLight";
  src: url("/src/assets/fonts/lexend/Lexend-Light.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendMedium";
  src: url("/src/assets/fonts/lexend/Lexend-Medium.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendRegular";
  src: url("/src/assets/fonts/lexend/Lexend-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendSemiBold";
  src: url("/src/assets/fonts/lexend/Lexend-SemiBold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "LexendThin";
  src: url("/src/assets/fonts/lexend/Lexend-Thin.ttf");
  font-weight: 400;
}

html {
  font-size: 1.25vw;
  scroll-behavior: smooth;
  /* overflow:initial !important; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "LexendRegular", sans-serif;
  font-style: normal;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

h1 {
  color: var(--blackPrimary);
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.75rem;
  letter-spacing: -0.045rem;
  font-family: "LexendSemiBold", sans-serif;
}

h2 {
  color: var(--blackPrimary);
  text-align: center;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.375rem;
  font-family: "LexendBold", sans-serif;
}

p,
::placeholder {
  color: var(--darkGrayPrimary);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.ant-select-item-option-content {
  font-size: 1rem;
}
.ant-form-item-row,
.ant-form-item-control-input,
.ant-form-item-control-input-content {
  height: 100% !important;
}

::placeholder {
  text-align: start;
  color: var(--lightGraySecondary);
}

:where(.css-dev-only-do-not-override-2i2tap).ant-picker:hover,
:where(.css-dev-only-do-not-override-2i2tap).ant-picker:focus {
  border-color: var(--lightGraySecondary);
}

span,
small {
  color: var(--blackSecondary);
  font-weight: 500;
  line-height: 1.25rem;
  font-size: 1rem;
}

a {
  color: var(--bluePrimary);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.dropdown__items {
  font-size: 0.8rem;
  color: var(--blackPrimary);
  font-weight: 300;
  font-family: "LexendRegular", sans-serif;
  margin: 0;
  min-width: 8rem;
  text-align: start;
}

.dropdown__items svg {
  margin: 0 0.001rem -0.1rem 0;
}

.dropdown__items__blue {
  color: var(--bluePrimary) !important;
  text-align: start;
}

.dropdown__items__red {
  color: var(--errorPrimary) !important;
}

.ant-popover .ant-popover-inner {
  background-color: var(--blackSecondary);
  padding: 7px 10px;
}

.ant-popover-arrow {
  color: var(--blackSecondary);
}

.ant-popover-inner-content {
  color: #fff !important;
  font-size: 0.9rem;
}

img,
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
::placeholder {
  text-transform: capitalize;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:disabled,
:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default.ant-btn-disabled {
  background-color: var(--lightGrayPrimary);
}

:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:disabled span,
:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default.ant-btn-disabled
  span {
  color: #fff;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-picker-focused.ant-picker {
  border-color: #eee;
  box-shadow: none;
}
.ant-dropdown-menu {
  border-radius: 0 !important;
}

.loader {
  height: 20rem;
}

.ant-spin-container {
  align-items: flex-end !important;
}

.ant-notification-notice-wrapper
  .ant-notification-notice.verify-bvn-success
  .ant-notification-notice-content
  .ant-notification-notice-message,
.ant-notification-notice-wrapper
  .ant-notification-notice.verify-bvn-success
  .ant-notification-notice-content
  .ant-notification-notice-description,
.ant-notification-notice-wrapper
  .ant-notification-notice.verify-bvn-success
  .ant-notification-notice-close
  .ant-notification-notice-close-icon {
  color: white;
}

.ant-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}

.verify_bvn_disclaimer .verify_bvn_disclaimer_title {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  color: #344054;
  text-align: left;
}

.verify_bvn_disclaimer .verify_bvn_disclaimer_subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: #646464;
  text-align: left;
}

@media (max-width: 1950px) and (max-height: 1080px) {
  html {
    font-size: 1vw;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 1.15vw;
  }
}

@media (max-width: 1400px) and (max-height: 1020px) {
  html {
    font-size: 1.3vw;
  }
}

@media (max-width: 1300px) and (min-height: 1020px) {
  html {
    font-size: 1.8vw;
  }
}

@media (max-width: 1050px) {
  html {
    font-size: 2.5vw;
  }
}

@media (max-width: 1025px) and (max-height: 770px) {
  html {
    font-size: 1.8vw;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 3.3vw;
  }
}
