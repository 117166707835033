:root {
  --bluePrimary: #0062d6;
  --blackPrimary: #101828;
  --blackSecondary: #353535;
  --darkGrayPrimary: #646464;
  --lightGrayPrimary: #9f9f9f;
  --lightGraySecondary: #c8c8c8;
  --lightGrayTertiary: #d9d9d9;
  --errorPrimary: #e33f3f;
  --successPrimary: #10b981;
}
